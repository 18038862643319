import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const Navbar = lazy(() => import('./pages/Navbar/Navbar'));
const Slider = lazy(() => import('./pages/Slider/Slider'));
const Mainfooter = lazy(() => import('./Components/Mainfooter/Mainfooter'));
const NewsLetter = lazy(() => import('./pages/NewsLetter/NewsLetter'));
const Category = lazy(() => import('./pages/Shop1/Components/Category'));
const Womens = lazy(() => import('./pages/Shop1/Components/Womens'));
const Kids = lazy(() => import('./pages/Shop1/Pages/Kids'));
const Contactus = lazy(() => import('./Components/Contactus'));
const WeddingCollections = lazy(() => import('./pages/Shop1/Components/WeddingCollections'));
const Shop11 = lazy(() => import('./pages/Shop1/Pages/Shop11'));
const Shopboutique1 = lazy(() => import('./pages/Shop1/Pages/Shopboutique1'));

const Recently = lazy(() => import('./Components/Recently'));
const Shopboutique4 = lazy(() => import('./pages/Shop4/Pages/Shopboutique4'));
const EthnicKurtas = lazy(() => import('./pages/Shop4/Pages/EthnicKurtas'));
const Shopdress = lazy(() => import('./pages/Shop4/Pages/Shopdress'));
const Navbar4 = lazy(() => import('./Components/Navbar4'));
const Shopboutique5 = lazy(() => import('./pages/Shop5/components/Shopboutique5'));
const EthnicKurtas1 = lazy(() => import('./pages/Shop5/components/EthnicKurtas1'));
const Shopboutique6 = lazy(() => import('./pages/Shop6/Pages/Shopboutique6'));
const Navbar6 = lazy(() => import('./pages/Shop6/Pages/Navbar6'));

const Superadmin = lazy(() => import('./superadmin/superadmin'));
const Loginpage = lazy(() => import('./Components/componentsdashboard/loginpage'));
const Register = lazy(() => import('./Components/Register'));
const Admin = lazy(() => import('./admin/admin'));

const Login = lazy(() => import('./Components/componentsdashboard/Login'));
const Dashboard = lazy(() => import('./admin/pages/Dashboard'));
const Superadmindashboard = lazy(() => import('./superadmin/Superadmindashboard'));
const Profile = lazy(() => import('./admin/pages/profile'));
const Memberentry = lazy(() => import('./admin/pages/memberentry'));
const Memberpassbook = lazy(() => import('./admin/pages/Memberpassbook'));
const Whoweare = lazy(() => import('./Components/Whoweare'));
const Virtual = lazy(() => import('./Components/Virtual/Virtual'));

const Categories = lazy(() => import('./admin/pages/Categories'));
const Addboutique = lazy(() => import('./Components/Addboutique'));
const Sarees = lazy(() => import('./admin/pages/Sarees'));
const Paymentreport = lazy(() => import('./admin/pages/Paymentreport'));
const Adminmanagment = lazy(() => import('./superadmin/Adminmanagment'));
const CustomerManagment = lazy(() => import('./superadmin/CustomerManagment'));
const Admincredentials = lazy(() => import('./superadmin/Admincredentials'));
const Admindetails = lazy(() => import('./superadmin/Admindetails'));
const Productdetail = lazy(() => import('./pages/Shop4/Productdetail'));
const User = lazy(() => import('./Components/User'));

const OwnerInfo = lazy(() => import('./admin/pages/OwnerInfo'));
const UserManagment = lazy(() => import('./superadmin/UserManagment'));
const FeedbackManagment = lazy(() => import('./superadmin/FeedbackManagement'));
const Myaccount = lazy(() => import('./Components/Myaccount'));
const Cartpage = lazy(() => import('./Components/Cartpage'));
const Page = lazy(() => import('./Components/Page'));
const Landingpages = lazy(() => import('./admin/pages/Landingpages'));
const ProductPage = lazy(() => import('./pages/Shop2/Components/Pages/ProductPage'));
const ProductPage1 = lazy(() => import('./pages/Shop2/Components/Pages/ProductPage1'));
const ProductPage2 = lazy(() => import('./pages/Shop2/Components/Pages/ProductPage2'));
const Product = lazy(() => import('./pages/Shop2/Components/Pages/Product'));
const Product1 = lazy(() => import('./pages/Shop2/Components/Pages/Product1'));
const Product2 = lazy(() => import('./pages/Shop2/Components/Pages/Product2'));
const Product3 = lazy(() => import('./pages/Shop2/Components/Pages/Product3'));
const Product4 = lazy(() => import('./pages/Shop2/Components/Pages/Product4'));
const Product5 = lazy(() => import('./pages/Shop2/Components/Pages/Product5'));
const Product6 = lazy(() => import('./pages/Shop2/Components/Pages/Product6'));
const Product7 = lazy(() => import('./pages/Shop2/Components/Pages/Product7'));
const Shopboutique2 = lazy(() => import('./pages/Shop2/Components/Pages/Shopboutique2'));
const Forgetpassword = lazy(() => import('./Components/Forgetpassword'));
const Wishlist = lazy(() => import('./Components/Wishlist'));

function App() {
  return (
    <div className="App">
      <Router>
      <Suspense fallback={
          <div className="dot-spinner">
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
          </div>
        }>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/Shopdress/:id/:cid' element={<Shopdress/>} />
            <Route path='/Navbar' element={<Navbar />} />
            <Route path='/Mainfooter' element={<Mainfooter />} />
            
            <Route path='/Shopboutique1' element={<Shopboutique1 />} />
            <Route path='/collections' element={<Category />} />
            <Route path='/womens' element={<Womens />} />
            <Route path='/kids' element={<Kids />} />
            <Route path='/wedding-collections' element={<WeddingCollections />} />
            <Route path='/Contactus' element={<Contactus />} />
            <Route path='/Shop11' element={<Shop11 />} />
            <Route path='/Landingpages' element={<Landingpages />} />
            <Route path='/Recent' element={<Recently />} />
            <Route path="/Shopboutique4/:id" element={<Shopboutique4 />} />
            <Route path="/EthnicKurtas/:id" element={<EthnicKurtas />} />
            <Route path="/Navbar4/:id" element={<Navbar4 />} />
            <Route path="/Shopboutique5/:id" element={<Shopboutique5 />} />
            <Route path="/EthnicKurtas1" element={<EthnicKurtas1 />} />
            <Route path="/Shopboutique6/:id" element={<Shopboutique6 />} />
            <Route path="/Navbar6" element={<Navbar6 />} />
           
            <Route path="/Wishlist" element={<Wishlist />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/Loginpage" element={<Loginpage />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Superadmin" element={<Superadmin />} />
            
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Paymentreport" element={<Paymentreport />} />
            <Route path="/memberentry" element={<Memberentry />} />
            <Route path="/memberpassbook" element={<Memberpassbook />} />
            <Route path="/Profile" element={<Profile />} />
           
            <Route path="/OwnerInfo" element={<OwnerInfo />} />
           
            <Route path="/Superadmindashboard" element={<Superadmindashboard />} />
            <Route path="/Adminmanagment" element={<Adminmanagment />} />
            <Route path="/whoweare" element={<Whoweare />} />
            <Route path="/virtual" element={<Virtual />} />
            <Route path="/Categories" element={<Categories />} />
            <Route path="/Addboutique" element={<Addboutique />} />
            <Route path="/Sarees" element={<Sarees />} />
            <Route path="/Cartpage" element={<Cartpage />} />
            <Route path="/Categories/:id" element={<Sarees />} />
            <Route path="/Productdetail/:id" element={<Productdetail />} />
            <Route path="/Customermanagment" element={<CustomerManagment />} />
            <Route path="/FeedbackManagment" element={<FeedbackManagment />} />
            <Route path="/Admincredentials" element={<Admincredentials />} />
            <Route path="/Admindetails" element={<Admindetails />} />
            <Route path="/UserManagment" element={<UserManagment />} />
            <Route path="/user" element={<Myaccount />} />
            <Route path="/Forgetpassword" element={<Forgetpassword />} />
            <Route path="/Shopboutique2/:id" element={<Shopboutique2 />} />
            <Route path="/ProductPage" element={<ProductPage />} />
            <Route path="/ProductPage1" element={<ProductPage1 />} />
            <Route path="/ProductPage2" element={<ProductPage2 />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/Product1" element={<Product1 />} />
            <Route path="/Product2" element={<Product2 />} />
            <Route path="/Product3" element={<Product3 />} />
            <Route path="/Product4" element={<Product4 />} />
            <Route path="/Product5" element={<Product5 />} />
            <Route path="/Product6" element={<Product6 />} />
            <Route path="/Product7" element={<Product7 />} />
            <Route path="/Page" element={<Page />} />
            <Route path="/Slider" element={<Slider />} />
            <Route path="/NewsLetter" element={<NewsLetter />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
